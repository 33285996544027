import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    wordBreak: "initial",
  },
  textWrapper: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: rem(14),
  },
  text: {
    whiteSpace: "pre-wrap",
  },
}));
