import {
  Button,
  Flex,
  Input,
  MediaQuery,
  Modal,
  ScrollArea,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, matches, useForm } from "@mantine/form";
import { isNull } from "lodash";
import { useCallback } from "react";

import {
  ClikCity,
  ClikCountry,
  FileResult,
} from "../../../graphql/generated.ts";
import { RawGroup } from "../../../types/limitRequest/group.ts";
import {
  getClickCity,
  getClikCityOptions,
  getClikCountry,
  getClikCountryOptions,
} from "../../../utils/clikData.ts";
import { phoneMatcher, toClikPhoneFormat } from "../../../utils/phone.ts";
import Dropzone from "../../Dropzone/Dropzone.tsx";

interface GroupFormProps {
  opened: boolean;
  value: RawGroup | null;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  disabledFields?: string[] | null;
  onClose: () => void;
  onSubmit: (value: RawGroup) => void;
}

export const GroupFormModal = ({
  opened,
  value,
  cities,
  countries,
  disabledFields,
  onClose,
  onSubmit,
}: GroupFormProps) => {
  const form = useForm({
    initialValues: {
      id: value?.id,
      name: value?.name,
      bussinessIdentifyNumber: value?.bussinessIdentifyNumber,
      phone: value?.phone,
      address: value?.address,
      subDistrict: value?.subDistrict,
      district: value?.district,
      city: value?.city,
      postalCode: value?.postalCode,
      country: value?.country,
      npwpFile: value?.npwpFile,
      registrationForm: value?.registrationForm,
    },
    validate: {
      name: matches(/^[A-Za-z\s]+$/, "Name must contain only letters"),
      phone: phoneMatcher,
      address: isNotEmpty("Field is required"),
      subDistrict: isNotEmpty("Field is required"),
      district: isNotEmpty("Field is required"),
      city: isNotEmpty("Field is required"),
      postalCode: matches(
        /^\d{5}$/,
        "The postal code must consist of a maximum of 5 characters. It can include only numbers"
      ),
      country: isNotEmpty("Field is required"),
      bussinessIdentifyNumber: isNotEmpty("Field is required"),
      npwpFile: isNotEmpty("Field is required"),
      registrationForm: isNotEmpty("Field is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleFileUploaded = (name: keyof RawGroup, files: FileResult[]) => {
    form.setValues({ [name]: files[0] });
  };

  const handleRemoveFile = (name: keyof RawGroup) => {
    form.setValues({ [name]: undefined });
  };

  const handleSetClikCity = (cityId: string) => {
    const clikCity = getClickCity(cityId, cities);
    form.setValues({
      city: {
        id: Number(cityId),
        name: clikCity?.name ?? "",
        code: clikCity?.code ?? "",
      },
    });
  };

  const handleSetClikCountry = (countryId: string) => {
    const clikCountry = getClikCountry(countryId, countries);
    form.setValues({
      country: {
        id: Number(countryId),
        name: clikCountry?.name ?? "",
        code: clikCountry?.code ?? "",
      },
    });
  };

  const handleSubmit = useCallback(() => {
    if (!form.isValid() || !form.values.phone) {
      return;
    }
    onSubmit({
      ...form.values,
      phone: toClikPhoneFormat(form.values.phone),
    } as RawGroup);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      size="xl"
      opened={opened}
      title={isNull(value) ? "Add Group" : "Update Group"}
      scrollAreaComponent={ScrollArea.Autosize}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20} pb={{ base: 84, sm: 0 }}>
        <SimpleGrid
          cols={2}
          breakpoints={[{ minWidth: "sm", cols: 2 }]}
          spacing={20}
          verticalSpacing={12}
        >
          <Flex direction="column" gap={{ base: 12, sm: 20 }}>
            <TextInput
              required
              label="Group Name"
              placeholder="Enter Group Name"
              size="m"
              disabled={disabledFields?.includes("name")}
              {...form.getInputProps("name")}
            />
            <TextInput
              required
              label="Bussiness Identify Number"
              placeholder="Enter Bussiness Identify Number"
              size="m"
              {...form.getInputProps("bussinessIdentifyNumber")}
            />
            <TextInput
              required
              label="Phone"
              placeholder="Enter Phone"
              size="m"
              {...form.getInputProps("phone")}
            />
            <Input.Wrapper required label="NPWP File" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={form?.values?.npwpFile ? [form.values.npwpFile] : null}
                onUploadSuccess={(files: FileResult[]) =>
                  handleFileUploaded("npwpFile", files)
                }
                onRemove={() => handleRemoveFile("npwpFile")}
              />
            </Input.Wrapper>
            <Input.Wrapper required label="Registration Form" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={
                  form?.values?.registrationForm
                    ? [form.values.registrationForm]
                    : null
                }
                onUploadSuccess={(files: FileResult[]) =>
                  handleFileUploaded("registrationForm", files)
                }
                onRemove={() => handleRemoveFile("registrationForm")}
              />
            </Input.Wrapper>
          </Flex>
          <Flex direction="column" gap={{ base: 12, sm: 20 }}>
            <TextInput
              required
              label="Address"
              placeholder="Enter Address"
              size="m"
              {...form.getInputProps("address")}
            />
            <TextInput
              required
              label="Sub-district"
              placeholder="Enter Sub-district"
              size="m"
              {...form.getInputProps("subDistrict")}
            />
            <TextInput
              required
              label="District"
              placeholder="Enter District"
              size="m"
              {...form.getInputProps("district")}
            />
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={getClikCityOptions(cities)}
              label="City"
              placeholder="Select City"
              size="m"
              searchable
              filterDataOnExactSearchMatch
              {...form.getInputProps("city")}
              value={form.values.city?.id.toString()}
              onChange={handleSetClikCity}
            />
            <TextInput
              required
              type="number"
              label="Postal Code"
              placeholder="Postal Code"
              size="m"
              {...form.getInputProps("postalCode")}
            />
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={getClikCountryOptions(countries)}
              label="Country"
              placeholder="Select Country"
              size="m"
              searchable
              filterDataOnExactSearchMatch
              {...form.getInputProps("countryId")}
              value={form.values.country?.id.toString()}
              onChange={handleSetClikCountry}
            />
          </Flex>
        </SimpleGrid>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Flex justify="flex-end" gap={20}>
            <Button
              uppercase
              variant="outlineBlue"
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              uppercase
              variant="filledBlue"
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Flex>
        </MediaQuery>
      </Flex>
    </Modal>
  );
};
