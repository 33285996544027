import { Flex, Input, MultiSelect, SimpleGrid, Switch } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import {
  CustomerFreezeStatus,
  CustomerOrderBy,
  OrderBy,
} from "../../graphql/generated.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useCustomers } from "../../hooks/api/customer/useCustomers.ts";
import { useLimits } from "../../hooks/api/limit/useLimits.ts";
import { activeLimitStatusOptions } from "../../types/limit.ts";
import { getApiOrderBy } from "../../utils/api.ts";
import {
  getCompanyOptions,
  getSalesNameOptions,
  getSalesTeamOptions,
} from "../../utils/company.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { useCustomersTableData } from "./hooks/useCustomersTableData.tsx";
import { CustomerFilterValue } from "./types.ts";
import { getCustomerApiFilter, getLimitApiFilter } from "./utils.ts";

const CustomerDatabasePage = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<CustomerFilterValue>({});
  const apiFilter = useMemo(() => {
    return getCustomerApiFilter(filter);
  }, [filter]);
  const apiLimitFilter = useMemo(() => {
    return getLimitApiFilter(filter);
  }, [filter]);

  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<CustomerOrderBy>({
    id: OrderBy.Desc,
  });

  const [
    {
      data: customersResponse,
      fetching: customersFetching,
      error: customersError,
    },
  ] = useCustomers({
    filter: { ...apiFilter, parentId: null },
    orderBy,
    page,
  });
  const customers = customersResponse?.customers.data;
  const pageCount = getPagesCount(customersResponse?.customers?.total);

  const [
    {
      data: customerOptionsResponse,
      fetching: customerOptionsFetching,
      error: customerOptionsError,
    },
  ] = useCompanyOptions();
  const companyOptions = customerOptionsResponse?.customers.data;

  const [
    { data: limitsResponse, fetching: limitsFetching, error: limitsError },
  ] = useLimits({ filter: apiLimitFilter });
  const limits = limitsResponse?.limits.data;

  const error = customersError || limitsError || customerOptionsError;

  const [columns, rows] = useCustomersTableData({
    customers,
    limits,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleFilterChange = useCallback(
    (key: keyof CustomerFilterValue, value: unknown) => {
      setFilter((prevFilter) => {
        return { ...prevFilter, [key]: value };
      });
      setPage(1);
    },
    []
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  if (error) {
    navigate(AppRoute.Home);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Layout>
      <Preloader loading={customerOptionsFetching} />
      <Flex direction="column" gap={{ base: 24, sm: 20 }}>
        <Flex gap={20} justify="space-between">
          <Flex gap={20}>
            <Title size="h1">Customer Database</Title>
          </Flex>
        </Flex>
        <Flex direction="column" gap={16}>
          <SimpleGrid
            w="100%"
            cols={3}
            breakpoints={[
              { maxWidth: "sm", cols: 2 },
              { maxWidth: "lg", cols: 3 },
            ]}
            spacing={20}
            verticalSpacing={12}
          >
            <MultiSelect
              size="m"
              clearable
              searchable
              label="Customer Name"
              placeholder="Select Customer Name"
              data={getCompanyOptions(companyOptions)}
              onChange={(value) =>
                handleFilterChange(
                  "id",
                  value.length ? value.map(Number) : null
                )
              }
            />
            <MultiSelect
              size="m"
              clearable
              label="Customer Status"
              placeholder="Select Customer Status"
              data={[
                { value: CustomerFreezeStatus.Inactive, label: "Active" },
                { value: CustomerFreezeStatus.Active, label: "Freeze" },
              ]}
              onChange={(value) => handleFilterChange("freezeStatus", value)}
            />
            <MultiSelect
              size="m"
              clearable
              label="Limit Status"
              placeholder="Select Limit Status"
              data={activeLimitStatusOptions}
              onChange={(value) => handleFilterChange("limitStatuses", value)}
            />
            <MultiSelect
              size="m"
              clearable
              searchable
              label="Sales Name"
              placeholder="Select Sales Name"
              data={getSalesNameOptions(companyOptions)}
              onChange={(value) => handleFilterChange("salesName", value)}
            />
            <MultiSelect
              size="m"
              clearable
              searchable
              label="Sales Team"
              placeholder="Select Sales Team"
              data={getSalesTeamOptions(companyOptions)}
              onChange={(value) => handleFilterChange("salesTeam", value)}
            />
            <Input.Wrapper label="Show only Customer with Limit">
              <Switch
                variant="yellow"
                checked={filter.hasLimits}
                size="lg"
                onClick={() =>
                  handleFilterChange("hasLimits", !filter.hasLimits)
                }
              />
            </Input.Wrapper>
          </SimpleGrid>
          <Table
            withColumnBorders
            columns={columns}
            rows={rows}
            verticalAlignment="top"
            defaultSort={{
              columnAccessor: "id",
              direction: SortDirection.desc,
            }}
            loading={customersFetching || limitsFetching}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            pinLastColumn
            onSortChange={handleOnSortChange}
          />
        </Flex>
      </Flex>
    </Layout>
  );
};

export default CustomerDatabasePage;
