import { SelectItem } from "@mantine/core/lib/Select/types";
import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import {
  RestructureApprovalStatusFilterOptions,
  RestructureDetailFilter,
  RestructureDetailOrderBy,
  RestructureFilter,
  RestructureOrderBy,
} from "../../graphql/generated";
import { RestructureFilters } from "../../pages/Review/RestructureReview/types";
import { Invoice } from "../../types/invoice/invoice.ts";
import { getApiOrderBy } from "../../utils/api";
import { RestructureDetailsFilters } from "./types";

export const invoiceIdOptions = (invoices: Invoice[]): SelectItem[] => {
  return (
    invoices?.map((invoice) => {
      return {
        value: invoice.id.toString(),
        label: invoice.invoiceNumber,
      };
    }) || []
  );
};

export const getRestructureApiSort = (
  tableSort: DataTableSortStatus
): RestructureOrderBy => {
  const { collectorAssigned, customerName, ...apiSort } =
    getApiOrderBy(tableSort);
  return {
    ...apiSort,
    customer: customerName && { name: customerName },
    collectorAssigned: collectorAssigned && { id: collectorAssigned },
  };
};

export const getRestructureApiFilter = (
  filters: Partial<RestructureFilters>
): RestructureFilter => {
  return pickBy({
    customerId: filters.customerId
      ? { equals: Number(filters.customerId) }
      : null,
    approvalStatus: filters.approvalStatus
      ? {
          equals:
            filters.approvalStatus as RestructureApprovalStatusFilterOptions | null,
        }
      : null,
  });
};

export const getRestructureDetailsApiSort = (
  tableSort: DataTableSortStatus
): RestructureDetailOrderBy => {
  const { invoiceNumber, ...apiSort } = getApiOrderBy(tableSort);
  return {
    ...apiSort,
    invoice: invoiceNumber && { invoiceNumber: invoiceNumber },
  };
};

export const getRestructureDetailsApiFilter = (
  filters: RestructureDetailsFilters
): RestructureDetailFilter => {
  return pickBy({
    restructureId: filters.restructureId
      ? { equals: filters.restructureId }
      : null,
    invoiceId: filters.invoiceId ? { equals: Number(filters.invoiceId) } : null,
  });
};
