import Preloader from "../../../components/Preloader/Preloader.tsx";
import { ClikEnquiryType } from "../../../graphql/generated.ts";
import { useClikResult } from "../../../hooks/api/clikResult/useClikResult.ts";
import ClikResultCompany from "./ClikResultCompany/ClikResultCompany.tsx";
import ClikResultIndividual from "./ClikResultIndividual/ClikResultIndividual.tsx";

interface ClikResultDetailsProps {
  clikResultId: number;
  onError: () => void;
}

const ClikResultDetails = ({
  clikResultId,
  onError,
}: ClikResultDetailsProps) => {
  const [{ data, fetching, error }] = useClikResult({ id: clikResultId });

  const clikResult = data?.clikResult;

  if (error || (!fetching && !clikResult)) {
    onError();
    return null;
  }

  if (fetching || !clikResult) {
    return <Preloader loading={fetching} />;
  }

  return (
    <>
      {clikResult.enquiryType === ClikEnquiryType.Individual ? (
        <ClikResultIndividual clikResult={clikResult} />
      ) : (
        <ClikResultCompany clikResult={clikResult} />
      )}
    </>
  );
};

export default ClikResultDetails;
