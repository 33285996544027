import { SelectItem } from "@mantine/core/lib/Select/types";
import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import {
  ApproveWaiveInput,
  WaiveApprovalStatusFilterOptions,
  WaiveFilter,
  WaiveOrderBy,
} from "../../graphql/generated";
import { Invoice } from "../../types/invoice/invoice.ts";
import { Waive } from "../../types/waive";
import { getApiOrderBy } from "../../utils/api";
import { WaiveFilters } from "./types";

export const toApproveWaiveInput = (
  value: Waive | ApproveWaiveInput | null | undefined
) => {
  if (!value) return;
  return {
    amount: value.amount,
    approvalStatus: value.approvalStatus,
    approvalDocument: value.approvalDocument ?? "",
    notes: value?.notes,
  };
};

export const invoiceIdOptions = (invoices: Invoice[]): SelectItem[] => {
  return (
    invoices?.map((invoice) => {
      return {
        value: invoice.id.toString(),
        label: invoice.invoiceNumber,
      };
    }) || []
  );
};

export const getWaiveApiSort = (
  tableSort: DataTableSortStatus
): WaiveOrderBy => {
  const { invoiceNumber, collectorAssigned, customerName, ...apiSort } =
    getApiOrderBy(tableSort);
  const invoiceFilter = {
    invoiceNumber,
    customer: customerName && { name: customerName },
  };
  return {
    ...apiSort,
    invoice: pickBy(invoiceFilter),
    collectorAssigned: collectorAssigned && { id: collectorAssigned },
  };
};

export const getWaiveApiFilter = (filters: WaiveFilters): WaiveFilter => {
  return pickBy({
    invoice: filters.customerId
      ? { customerId: { equals: Number(filters.customerId) } }
      : null,
    approvalStatus: filters.approvalStatus
      ? {
          equals:
            filters.approvalStatus as WaiveApprovalStatusFilterOptions | null,
        }
      : null,
  });
};
