import get from "lodash/get";
import isNil from "lodash/isNil";

import {
  CaResult,
  CreditApproval as GqlGeneratedCreditApproval,
  Maybe,
  User,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type CreditApproval = Omit<GqlGeneratedCreditApproval, "updatedBy"> & {
  updatedBy?: Maybe<Pick<User, "id" | "name" | "email">>;
};

export const toHumanReadableCAResult = (value: CaResult | null | undefined) => {
  if (isNil(value)) {
    return "";
  }
  const mapping = {
    [CaResult.ApprovedByCc]: "Approved by Committee",
    [CaResult.RejectedByCc]: "Rejected by Committee",
    [CaResult.ApprovedByRr]: "Approved by RR",
    [CaResult.RejectedByRr]: "Rejected by RR",
  };
  return get(mapping, value, toHumanReadable(value));
};

export const creditApprovalResultOptions = Object.values(CaResult).map(
  (value) => {
    return { value, label: toHumanReadableCAResult(value) };
  }
);

export const creditApprovalRRResultOptions = Object.values([
  CaResult.ApprovedByRr,
  CaResult.RejectedByRr,
]).map((value) => {
  return { value, label: toHumanReadableCAResult(value) };
});

export const creditApprovalCCResultOptions = Object.values([
  CaResult.ApprovedByCc,
  CaResult.RejectedByCc,
]).map((value) => {
  return { value, label: toHumanReadableCAResult(value) };
});
