import moment from "moment-timezone";

import { API_DATE_FORMAT } from "../constants.ts";

export const TIMEZONE = "Asia/Jakarta";

export const DATE_FORMAT = "MMM D, YYYY, HH:mm";

export const DAY_FORMAT = "MMM D, YYYY";

export const toLocalDateTime = (
  value: string | undefined | null
): string | null => {
  if (!value) {
    return null;
  }
  return moment.tz(value, TIMEZONE).format(DATE_FORMAT);
};

export const toLocalDate = (
  value: string | undefined | null
): string | null => {
  if (!value) {
    return null;
  }
  return moment.tz(value, TIMEZONE).format(DAY_FORMAT);
};

export const getDateString = (date: Date): string => {
  return moment(date).format(API_DATE_FORMAT);
};

export const getEndOfDayString = (date: Date): string => {
  const formattedDate = moment(date).format(`${API_DATE_FORMAT}T23:59:59.000`);
  return `${formattedDate}Z`;
};

export const getStartOfDayString = (date: Date): string => {
  const formattedDate = moment(date).format(`${API_DATE_FORMAT}T00:00:00.000`);
  return `${formattedDate}Z`;
};
