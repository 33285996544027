import {
  Badge,
  Flex,
  NumberInput,
  Select,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { DateInput, DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";

import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import { LATE_CHARGE_FEE } from "../../constants.ts";
import { PaymentStatusFilterOptions } from "../../graphql/generated.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useInvoices } from "../../hooks/api/invoice/useInvoices.ts";
import { Invoice } from "../../types/invoice/invoice.ts";
import { getCompanyOptions } from "../../utils/company.ts";
import { TIMEZONE } from "../../utils/date.ts";
import {
  parseStringToNumber,
  priceFormatter,
  toCurrencyPrice,
} from "../../utils/number.ts";

const LateFeeCalculatorPage = () => {
  const [invoice, setInvoice] = useState<Invoice | undefined>(undefined);
  const [calendarDisplayedDate, setCalendarDisplayedDate] = useState<
    Date | undefined
  >(undefined);

  const today = moment.tz(TIMEZONE).toDate();

  const form = useForm<{
    customerId: string | null | undefined;
    invoiceId: string | null | undefined;
    date: Date | null;
    invoiceAmount: number | null | undefined;
    invoiceDueDate: Date | null | undefined;
  }>({
    initialValues: {
      customerId: undefined,
      invoiceId: undefined,
      date: today,
      invoiceAmount: 0,
      invoiceDueDate: undefined,
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  const [{ data: customersResponse, fetching: fetchingCustomers }] =
    useCompanyOptions();
  const customers = customersResponse?.customers.data;

  const [{ data: invoicesData, fetching: fetchingInvoices }] = useInvoices({
    filter: {
      customerId: { equals: Number(form.values.customerId) },
      paymentStatus: {
        notIn: [
          PaymentStatusFilterOptions.Paid,
          PaymentStatusFilterOptions.PaidWithLateFee,
        ],
      },
    },
    pause: !form.values.customerId,
  });
  const invoices = invoicesData?.invoices?.data;
  const invoicesOptions = (invoices ?? []).map((invoice) => ({
    value: String(invoice.id),
    label: invoice.invoiceNumber,
  }));
  const customerHasInvoices = !!invoices?.length;

  const daysFromToday = Math.ceil(
    moment(form.values.date).tz(TIMEZONE).diff(today, "day", true)
  );

  const handleChangeCustomer = useCallback(
    (value: string | null) => {
      const resetValue = {
        invoiceId: null,
        invoiceAmount: 0,
        invoiceDueDate: null,
        date: today,
      };
      if (isNaN(Number(value))) {
        form.setValues(resetValue);
        return;
      }
      form.setValues({
        customerId: value ?? undefined,
        ...resetValue,
      });
    },
    [form, today]
  );

  const handleChangeInvoice = useCallback(
    (value: string | null) => {
      const invoiceId = Number(value);
      const resetValue = {
        invoiceId: null,
        invoiceAmount: 0,
        invoiceDueDate: null,
        date: today,
      };
      if (isNaN(invoiceId)) {
        form.setValues(resetValue);
        return;
      }
      const invoice = (invoices ?? []).find(
        (invoice) => invoice.id === invoiceId
      );
      if (!invoice) {
        form.setValues(resetValue);
        return;
      }
      setInvoice(invoice);
      const invoiceDueDate = invoice.invoiceDueDate
        ? moment(invoice.invoiceDueDate).tz(TIMEZONE).toDate()
        : undefined;
      form.setValues({
        invoiceId: value ?? undefined,
        invoiceAmount: Number(invoice.totalInvoice),
        invoiceDueDate,
        date: invoiceDueDate ?? today,
      });
      setCalendarDisplayedDate(invoiceDueDate ?? today);
    },
    [form, invoices, today]
  );

  const renderedCalendarBadge = useMemo(() => {
    let label = "Today";
    if (daysFromToday) {
      label = `${daysFromToday} day(s) from today`;
    }
    return (
      <Badge variant="goldenOutline" size="xl" radius="xs">
        {label}
      </Badge>
    );
  }, [daysFromToday]);

  const lateFee = useMemo(() => {
    if (!invoice) {
      return null;
    }
    const invoiceDueDate = moment(form.values.invoiceDueDate).tz(TIMEZONE);
    const date = moment(form.values.date).tz(TIMEZONE);
    const dpd = date.diff(invoiceDueDate, "days");
    if (invoice.removeLateCharge || dpd <= 0) {
      return 0;
    }
    const totalAmountDue = Number(invoice.totalAmountDue);
    const days = dpd > 90 ? 90 : dpd;
    return totalAmountDue * LATE_CHARGE_FEE * days;
  }, [form.values.date, form.values.invoiceDueDate, invoice]);

  return (
    <>
      <Layout>
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">Late Fee Calculator</Title>
          </Flex>
          <Preloader loading={fetchingCustomers || fetchingInvoices} />
          <SimpleGrid cols={3}>
            <Flex w="90%" direction="column" gap={16}>
              <Select
                w="100%"
                required
                searchable
                size="m"
                placeholder="Select Customer Name"
                label="Customer Name"
                data={getCompanyOptions(customers)}
                {...form.getInputProps("customerId")}
                onChange={(value) => handleChangeCustomer(value)}
              />
              {!!form.values.customerId && !customerHasInvoices && (
                <Text>This customer doesn&apos;t have unpaid invoices.</Text>
              )}
              <Select
                w="100%"
                required
                searchable
                size="m"
                placeholder="Select Invoice Number"
                label="Invoice Number"
                disabled={!customerHasInvoices || fetchingInvoices}
                data={invoicesOptions}
                {...form.getInputProps("invoiceId")}
                onChange={(value) => handleChangeInvoice(value)}
              />
              <NumberInput
                disabled
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                hideControls
                label="Invoice Amount"
                placeholder="Invoice Amount"
                size="m"
                {...form.getInputProps("invoiceAmount")}
              />
              <DateInput
                disabled
                label="Invoice Due Date"
                placeholder="Invoice Due Date"
                size="m"
                {...form.getInputProps("invoiceDueDate")}
              />
              {lateFee !== null && (
                <Badge variant="blueFilled" size="xl" radius="sm">
                  Late Fee: {toCurrencyPrice(lateFee) ?? ""}
                </Badge>
              )}
            </Flex>
            <Flex>
              <SimpleGrid cols={1}>
                <Flex direction="column" gap={16}>
                  <DatePicker
                    maw="100%"
                    size="xl"
                    locale={TIMEZONE}
                    minDate={invoice?.invoiceDueDate ?? today}
                    date={calendarDisplayedDate}
                    onDateChange={setCalendarDisplayedDate}
                    value={form.values.date}
                    onChange={(date) => {
                      form.setValues({ date });
                    }}
                  />
                  {renderedCalendarBadge}
                </Flex>
              </SimpleGrid>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Layout>
    </>
  );
};

export default LateFeeCalculatorPage;
