import { Select, SimpleGrid } from "@mantine/core";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal.tsx";
import SvgWarning from "../../../../components/Icons/Warning.tsx";
import ModalWithNotes from "../../../../components/ModalWithNotes/ModalWithNotes.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  LimitDetailFilter,
  LimitDetailOrderBy,
  LimitStatus,
  OrderBy,
} from "../../../../graphql/generated.ts";
import { useFreezeLimit } from "../../../../hooks/api/limit/useFreezeLimit.ts";
import { useInactivateLimit } from "../../../../hooks/api/limit/useInactivateLimit.ts";
import { useLimits } from "../../../../hooks/api/limit/useLimits.ts";
import { useUnFreezeLimit } from "../../../../hooks/api/limit/useUnFreezeLimit.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import {
  activeLimitStatusOptions,
  Limit,
  limitTypeOptions,
} from "../../../../types/limit.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { useLimitTableData } from "./hooks/useLimitTableData.tsx";
import { getLimitApiFilter, getLimitApiSort } from "./utils.ts";

interface Props {
  companyId: number | null | undefined;
  refreshLimitRequests: () => void;
}

export default function LimitTable({ companyId, refreshLimitRequests }: Props) {
  const [
    freezeModalOpened,
    { open: openFreezeModal, close: closeFreezeModal },
  ] = useDisclosure(false);
  const [
    inactivateConfirmationModalOpened,
    {
      open: openInactivateConfirmationModal,
      close: closeInactivateConfirmationModal,
    },
  ] = useDisclosure(false);

  const [selectedItem, setSelectedItem] = useState<Limit | null>(null);

  const [filter, setFilter] = useState<LimitDetailFilter>({});
  const [orderBy, setOrderBy] = useState<LimitDetailOrderBy>({
    id: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);
  const [{ data, fetching, error }, refreshData] = useLimits({
    filter: {
      ...filter,
      customerId: { equals: companyId },
    },
    orderBy,
    page,
  });

  const limits: Limit[] | undefined | null = data?.limits?.data;
  const pageCount = getPagesCount(data?.limits?.total);

  const [, unFreezeLimit] = useMutationNotificationWrapper(useUnFreezeLimit(), {
    success: { message: "Limit has been successfully unfrozen." },
  });

  const [, freezeLimit] = useMutationNotificationWrapper(useFreezeLimit(), {
    success: { message: "Limit has been successfully frozen." },
  });

  const [, inactivateLimit] = useMutationNotificationWrapper(
    useInactivateLimit(),
    {
      success: { message: "Limit has been successfully inactivated." },
    }
  );

  const handleOpenFreezeModal = useCallback(
    (value: Limit) => {
      setSelectedItem(value);
      openFreezeModal();
    },
    [openFreezeModal]
  );

  const handleOpenInactivateModal = useCallback(
    (value: Limit) => {
      setSelectedItem(value);
      openInactivateConfirmationModal();
    },
    [openInactivateConfirmationModal]
  );

  const handleCloseFreezeModal = useCallback(() => {
    setSelectedItem(null);
    closeFreezeModal();
  }, [closeFreezeModal, setSelectedItem]);

  const [columns, rows] = useLimitTableData({
    data: limits,
    onFreezeModalOpen: handleOpenFreezeModal,
    onInactivateModalOpen: handleOpenInactivateModal,
  });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getLimitApiSort(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | DatesRangeValue | null) => {
      setFilter(getLimitApiFilter({ [key]: value }));
    },
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleFreezeLimit = useCallback(
    (note: string) => {
      if (!selectedItem) {
        return;
      }

      const args = {
        id: selectedItem?.id,
        input: { text: note, limitId: selectedItem.id },
      };
      const handler =
        selectedItem.status === LimitStatus.Freeze
          ? unFreezeLimit(args)
          : freezeLimit(args);

      handler.then(() => {
        handleCloseFreezeModal();
        refreshData();
        refreshLimitRequests();
      });
    },
    [
      selectedItem,
      unFreezeLimit,
      freezeLimit,
      handleCloseFreezeModal,
      refreshData,
      refreshLimitRequests,
    ]
  );

  const handleInactivate = useCallback(() => {
    if (!selectedItem) {
      return;
    }

    closeInactivateConfirmationModal();
    inactivateLimit({ id: selectedItem?.id }).then(() => {
      refreshData();
      refreshLimitRequests();
    });
  }, [
    selectedItem,
    closeInactivateConfirmationModal,
    inactivateLimit,
    refreshData,
    refreshLimitRequests,
  ]);

  if (error) {
    notifications.clean();
    notifications.show({
      message: "Something went wrong while trying to fetch Limit data.",
      icon: <SvgWarning />,
    });
  }

  if (!companyId) {
    return;
  }

  return (
    <>
      <SectionTitle>Customer Limit</SectionTitle>
      <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
        <Select
          size="m"
          clearable
          label="Limit Status"
          placeholder="Select Limit Status"
          data={activeLimitStatusOptions}
          onChange={(value: string) => handleFilterChange("limitStatus", value)}
        />
        <Select
          size="m"
          clearable
          label="Limit Type"
          placeholder="Select Limit Type"
          data={limitTypeOptions}
          onChange={(value: string) => handleFilterChange("limitType", value)}
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleSortChange}
      />
      {freezeModalOpened && selectedItem && (
        <ModalWithNotes
          opened={true}
          title={
            selectedItem.status === LimitStatus.Freeze
              ? "Unfreeze Limit"
              : "Freeze Limit"
          }
          buttonTitle={
            selectedItem?.status === LimitStatus.Freeze ? "Unfreeze" : "Freeze"
          }
          onClose={handleCloseFreezeModal}
          onSubmit={handleFreezeLimit}
        />
      )}
      {inactivateConfirmationModalOpened && (
        <ConfirmationModal
          opened={true}
          message={
            "Are you sure you want to Inactivate this limit? This process is Irreversible."
          }
          onConfirm={handleInactivate}
          onClose={closeInactivateConfirmationModal}
        />
      )}
    </>
  );
}
