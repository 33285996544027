import { Button, Flex, Grid, Paper, Select, Text } from "@mantine/core";
import {
  MIME_TYPES,
  MS_EXCEL_MIME_TYPE,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useCallback } from "react";

import Dropzone from "../../components/Dropzone/Dropzone.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import { Loader } from "../../components/Loader.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import SectionTitle from "../../components/Section/SectionTitle/SectionTitle.tsx";
import Title from "../../components/Title/Title.tsx";
import {
  AiPromptStatusFilterOptions,
  FileResult,
  OrderBy,
} from "../../graphql/generated.ts";
import { useAIPrompts } from "../../hooks/api/ai/prompt/useAIPrompts.ts";
import { useRunAIPrompt } from "../../hooks/api/ai/prompt/useRunAIPrompt.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { getCompanyOptions } from "../../utils/company.ts";

export const AIAutomationPage = () => {
  const form = useForm<{
    customerId: string | null | undefined;
    promptId: string | null | undefined;
    files: FileResult[];
  }>({
    initialValues: {
      customerId: undefined,
      promptId: undefined,
      files: [],
    },
    validate: {
      customerId: isNotEmpty("Customer is required"),
      promptId: isNotEmpty("Prompt is required"),
      files: isNotEmpty("Files are required"),
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  const [{ data: customersResponse, fetching: fetchingCustomers }] =
    useCompanyOptions();
  const customers = customersResponse?.customers.data;

  const [{ data: promptsResponse, fetching: fetchingPrompts }] = useAIPrompts({
    filter: { status: { equals: AiPromptStatusFilterOptions.Active } },
    orderBy: {
      createdAt: OrderBy.Desc,
    },
  });
  const prompts = promptsResponse?.prompts || [];
  const promptsOptions = prompts.map((p) => ({
    value: String(p.id),
    label: p.title,
  }));

  const [runPromptState, runPrompt] = useRunAIPrompt();
  const runResult = runPromptState.data?.runAIPrompt.result;

  const handleRunPrompt = useCallback(() => {
    const input = {
      promptId: Number(form.values.promptId),
      customerId: Number(form.values.customerId),
      files: form.values.files.map((f) => f.filePath),
    };
    runPrompt({ input })
      .then((data) => {
        if (data.error) {
          notifications.show({
            message: "Something went wrong while running the prompt.",
            icon: <SvgWarning />,
          });
          return;
        }
        const result = data.data?.runAIPrompt;
        if (result?.error) {
          notifications.show({
            message: result.error,
            icon: <SvgWarning />,
          });
          return;
        }
      })
      .catch(() => {
        notifications.show({
          message: "Something went wrong while running the prompt.",
          icon: <SvgWarning />,
        });
      });
  }, [form.values, runPrompt]);

  const handleUploadFiles = (files: FileResult[]) => {
    form.setValues((values) => ({
      files: [...(values.files ?? []), ...files],
    }));
  };

  const handleRemoveFile = (filePath: string) => {
    form.setValues({
      files: form.values.files.filter((f) => f.filePath !== filePath),
    });
  };

  return (
    <>
      <Layout>
        <Preloader loading={fetchingCustomers || fetchingPrompts} />
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">AI Automation</Title>
            <Grid columns={3} gutter={32}>
              <Grid.Col lg={1}>
                <Flex direction="column" gap={16}>
                  <Select
                    required
                    searchable
                    clearable
                    size="lg"
                    placeholder="Select Customer Name"
                    data={getCompanyOptions(customers)}
                    {...form.getInputProps("customerId")}
                  />
                  <Select
                    required
                    searchable
                    clearable
                    size="lg"
                    placeholder="Select Prompt"
                    data={promptsOptions}
                    {...form.getInputProps("promptId")}
                  />
                  <Dropzone
                    w="100%"
                    maxWidth="100%"
                    maxFiles={5}
                    accept={[
                      ...PDF_MIME_TYPE,
                      ...MS_EXCEL_MIME_TYPE,
                      MIME_TYPES.png,
                      MIME_TYPES.jpeg,
                    ]}
                    subLabel="Only .pdf, .png, .jpeg files up to 5MB are allowed"
                    value={form.values.files}
                    onUploadSuccess={handleUploadFiles}
                    onRemove={handleRemoveFile}
                  />
                  <Button
                    w="100%"
                    variant="outlineBlue"
                    size="lg"
                    disabled={!form.isValid()}
                    loading={runPromptState.fetching}
                    onClick={handleRunPrompt}
                  >
                    RUN PROMPT
                  </Button>
                </Flex>
              </Grid.Col>
              <Grid.Col lg={2}>
                <Flex
                  direction="column"
                  gap={16}
                  style={{ width: "100%", height: "100%" }}
                >
                  {runPromptState.fetching && <Loader />}
                  {runResult && (
                    <>
                      <SectionTitle flex={0} isSmall variant="golden">
                        RESULT
                      </SectionTitle>
                      <Paper
                        shadow="xs"
                        p="md"
                        style={{ width: "100%", height: "100%" }}
                      >
                        {runResult.map((block, index) => (
                          <Text
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: block.text.replace(/\n/g, "<br/>"),
                            }}
                          />
                        ))}
                      </Paper>
                    </>
                  )}
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};
