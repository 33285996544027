import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { LimitUtilizationType } from "../../../../graphql/generated.ts";
import { LimitUtilization } from "../../../../types/riskFunnelDashboard.ts";
import { toLocalNumber, toPercent } from "../../../../utils/number.ts";
import { LimitUtilizationNameOptions } from "../constants.ts";

interface Props {
  data: LimitUtilization[] | null | undefined;
}

export const useLimitUtilizationTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "limitUtilizationName",
    },
    {
      accessor: "topUpLimit",
      title: "TOP Up Limit",
    },
    {
      accessor: "topBronze",
      title: "TOP Bronze",
    },
    {
      accessor: "topSilver",
      title: "TOP Silver",
    },
    {
      accessor: "topGold",
      title: "TOP Gold",
    },
    {
      accessor: "topExpress",
      title: "TOP Express",
    },
    {
      accessor: "projectsLimit",
      title: "Project Limit",
    },
  ];

  const getValueFormatter = useCallback((type: LimitUtilizationType) => {
    switch (type) {
      case LimitUtilizationType.Number:
        return toLocalNumber;
      case LimitUtilizationType.Percentage:
        return toPercent;
      default:
        return null;
    }
  }, []);

  const formatValue = useCallback(
    (v: number | null | undefined, type: LimitUtilizationType) => {
      const formatter = getValueFormatter(type);
      const value = v ? v : 0;
      return formatter ? formatter(value) : value;
    },
    [getValueFormatter]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        limitUtilizationName:
          LimitUtilizationNameOptions[item.limitUtilizationName],
        topUpLimit: formatValue(item.topUpLimit, item.limitUtilizationType),
        topBronze: formatValue(item.topBronze, item.limitUtilizationType),
        topSilver: formatValue(item.topSilver, item.limitUtilizationType),
        topGold: formatValue(item.topGold, item.limitUtilizationType),
        topExpress: formatValue(item.topExpress, item.limitUtilizationType),
        projectsLimit: formatValue(
          item.projectsLimit,
          item.limitUtilizationType
        ),
      };
    });
  }, [data, formatValue]);

  return [columns, rows];
};
