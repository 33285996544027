import { ActionIcon, Button, Flex, Tabs } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { isNull } from "lodash";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgSnowflake from "../../components/Icons/Snowflake.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import LateFeeTab from "../../components/LateFeeTab/LateFeeTab.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import ModalWithNotes from "../../components/ModalWithNotes/ModalWithNotes.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { CustomerFreezeStatus } from "../../graphql/generated.ts";
import { useClikCities } from "../../hooks/api/clikData/useClikCities.ts";
import { useClikCountries } from "../../hooks/api/clikData/useClikCountries.ts";
import { useClikData } from "../../hooks/api/clikData/useClikData.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useCustomerChangeLog } from "../../hooks/api/customer/useCustomerChangeLog.ts";
import { useFreezeCustomerStatus } from "../../hooks/api/customer/useFreezeCustomerStatus.ts";
import { useUnFreezeCustomerStatus } from "../../hooks/api/customer/useUnFreezeCustomerStatus.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useTabFromUrl } from "../../hooks/useTabFromUrl.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { useStyles } from "./ClikDataDetailsPage.styles.ts";
import ClikResultList from "./ClikResultList/ClikResultList.tsx";
import CompanyInfo from "./CompanyInfo/CompanyInfo.tsx";
import { tabs, TabValue } from "./constants.tsx";
import { CustomerStatusLog } from "./CustomerStatusLog/CustomerStatusLog.tsx";
import InvoicesHistoryList from "./InvoicesHistory/InvoicesHistoryList.tsx";
import LimitInfo from "./LimitInfo/LimitInfo.tsx";
import PengurusInfo from "./PengurusInfo/PengurusInfo.tsx";
import TransactionHistoryList from "./TransactionHistory/TransactionHistoryList.tsx";

const ClikDataDetailsPage = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const { user } = useCurrentUserContext();

  const { id } = useParams() as { id: string };

  const [
    { data: clikDataResponse, fetching: clikDataFetching, error },
    refreshClikData,
  ] = useClikData({ id });
  const clikData = clikDataResponse?.clikData;
  const limitRequest = clikData?.limitRequest;
  const customerId = limitRequest?.company?.id;

  const [{ data: clikCitiesData, fetching: clikCitiesFetching }] =
    useClikCities();

  const [{ data: clikCountriesData, fetching: clikCountriesFetching }] =
    useClikCountries();

  const [{ data: companiesData, fetching: companiesFetching }] =
    useCompanyOptions();

  const [
    { data: customerChangeLogData, fetching: customerChangeLogFetching },
    refreshCustomerChangeLog,
  ] = useCustomerChangeLog({
    id: customerId,
  });
  const customerChangeLog = customerChangeLogData?.customerChangeLog;

  const [, freezeCustomerStatus] = useMutationNotificationWrapper(
    useFreezeCustomerStatus(),
    {
      success: {
        message: "Customer Freeze Status has been changed successfully.",
      },
    }
  );

  const [, unFreezeCustomerStatus] = useMutationNotificationWrapper(
    useUnFreezeCustomerStatus(),
    {
      success: {
        message: "Customer Freeze Status has been changed successfully.",
      },
    }
  );

  const [
    showFreezeConfirmationModal,
    { open: openFreezeConfirmationModal, close: closeFreezeConfirmationModal },
  ] = useDisclosure(false);

  const isCustomerFreezed =
    limitRequest?.company?.freezeStatus === CustomerFreezeStatus.Active;

  const isFetching =
    clikCitiesFetching ||
    clikCountriesFetching ||
    companiesFetching ||
    clikDataFetching;

  const displayTabs = tabs.filter((item) =>
    item.show ? item.show(user) : true
  );
  const displayTabValues = displayTabs.map((item) => item.value);
  const activeTab = useTabFromUrl({
    defaultTab: displayTabValues[0],
    tabs: displayTabValues,
  });

  const handleBack = () => navigate(AppRoute.ClikData);

  if (error || isNull(clikData)) {
    navigate(AppRoute.ClikData);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  const handleChangeFreezeCustomerStatus = useCallback(
    (reason: string) => {
      if (!customerId) {
        return;
      }
      const handler = isCustomerFreezed
        ? unFreezeCustomerStatus
        : freezeCustomerStatus;
      handler({
        id: customerId,
        reason,
      }).then(() => {
        closeFreezeConfirmationModal();
        refreshClikData();
        refreshCustomerChangeLog();
      });
    },
    [
      closeFreezeConfirmationModal,
      customerId,
      freezeCustomerStatus,
      isCustomerFreezed,
      refreshClikData,
      refreshCustomerChangeLog,
      unFreezeCustomerStatus,
    ]
  );

  const freezeStatusCustomerButton = useMemo(() => {
    if (isCustomerFreezed) {
      return (
        <Button
          variant="outlineGolden"
          uppercase
          onClick={openFreezeConfirmationModal}
        >
          Unfreeze Customer
        </Button>
      );
    }
    return (
      <Button
        variant="filledGolden"
        leftIcon={<SvgSnowflake />}
        uppercase
        onClick={openFreezeConfirmationModal}
      >
        Freeze Customer
      </Button>
    );
  }, [isCustomerFreezed, openFreezeConfirmationModal]);

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 24, sm: 20 }}>
        <Flex gap={20} justify="space-between">
          <Flex gap={20}>
            <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
              <SvgChevronLeft />
            </ActionIcon>
            <Title className={classes.titleWrapper}>
              {limitRequest?.company?.name
                ? `Buyer Information - ${limitRequest.company.name}`
                : "Buyer Information"}
            </Title>
          </Flex>
          <Flex gap={20}>
            {freezeStatusCustomerButton}
            <CustomerStatusLog
              data={customerChangeLog}
              loading={customerChangeLogFetching}
            />
          </Flex>
        </Flex>
        <Tabs
          value={activeTab}
          variant="outline"
          keepMounted={false}
          onTabChange={(value) => navigate(`?tab=${value}`)}
        >
          <Tabs.List>
            {displayTabs.map((item, index) => {
              return (
                <Tabs.Tab key={index} value={item.value}>
                  {item.label}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
          <Tabs.Panel value={TabValue.CompanyInfo}>
            <CompanyInfo
              limitRequest={limitRequest}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
              companies={companiesData?.customers.data}
              isFetching={isFetching}
              onRefresh={refreshClikData}
            />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.PengurusInfo} mih="20vh">
            <PengurusInfo
              limitRequestId={limitRequest?.id}
              loading={isFetching}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
            />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.ClikResult}>
            <ClikResultList companyId={customerId} />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.LimitInfo}>
            <LimitInfo company={limitRequest?.company} />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.TransactionHistory}>
            <TransactionHistoryList customer={limitRequest?.company} />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.InvoicesHistory}>
            <InvoicesHistoryList customer={limitRequest?.company} />
          </Tabs.Panel>
          <Tabs.Panel value={TabValue.LateFee}>
            <LateFeeTab customerId={limitRequest?.company.id} />
          </Tabs.Panel>
        </Tabs>
      </Flex>
      {showFreezeConfirmationModal && (
        <ModalWithNotes
          opened={true}
          title={`Are you sure you want to ${
            isCustomerFreezed ? "Unfreeze" : "Freeze"
          } this Customer?`}
          buttonTitle={isCustomerFreezed ? "Unfreeze" : "Freeze"}
          input={{
            label: `Please provide the reason for ${
              isCustomerFreezed ? "Unfreezing" : "Freezing"
            } this customer.*`,
            placeholder: "Enter Reason",
            validationError: "Reason is required",
          }}
          onSubmit={handleChangeFreezeCustomerStatus}
          onClose={closeFreezeConfirmationModal}
        />
      )}
    </Layout>
  );
};

export default ClikDataDetailsPage;
