import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
} from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import get from "lodash/get";
import omit from "lodash/omit";
import { useCallback } from "react";

import Dropzone from "../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import {
  FileResult,
  RrAnalysisResult,
  UpdateRiskReviewInput,
} from "../../../graphql/generated.ts";
import {
  LimitRequestDetail,
  pricingApprovedMapping,
  tenorOptions,
} from "../../../types/limitRequest/limitRequest.ts";
import {
  RiskReview,
  rrAnalysisResultOptions,
  rrRiskCategoryOptions,
} from "../../../types/riskReview.ts";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";
import { useStyles } from "./RiskReviewModal.styles.ts";

interface Props {
  value: Partial<RiskReview> | null | undefined;
  limitRequest:
    | Pick<LimitRequestDetail, "rrAssigned" | "rrAssignedDate">
    | null
    | undefined;
  assigneesOptions: SelectItem[];
  opened: boolean;
  onClose: () => void;
  onSubmit: (
    assignedId: number,
    assignedDate: Date,
    value: UpdateRiskReviewInput
  ) => void;
}

const RiskReviewModal = ({
  value,
  limitRequest,
  assigneesOptions,
  opened,
  onClose,
  onSubmit,
}: Props) => {
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      assignedDate: limitRequest?.rrAssignedDate
        ? new Date(limitRequest.rrAssignedDate)
        : undefined,
      assignedId: limitRequest?.rrAssigned?.id,
      analysisResultDate: value?.analysisResultDate
        ? new Date(value.analysisResultDate)
        : undefined,
      analysisResult: value?.analysisResult,
      limitRecommendation: value?.limitRecommendation
        ? Number(value.limitRecommendation)
        : 0,
      tenorRecommendation: value?.tenorRecommendation
        ? String(value.tenorRecommendation)
        : undefined,
      notes: value?.notes,
      pricingRecommendation: value?.pricingRecommendation
        ? Number(value.pricingRecommendation)
        : 0,
      riskCategory: value?.riskCategory,
      document: value?.document,
    },
    validate: {
      assignedDate: isNotEmpty("RR Assigned Date is required"),
      assignedId: isNotEmpty("RR Assigned is required"),
      analysisResultDate: isNotEmpty("RR Analysis Result Date is required"),
      analysisResult: isNotEmpty("RR Analysis Result is required"),
      limitRecommendation: isNotEmpty("RR Limit Recommendation is required"),
      tenorRecommendation: isNotEmpty("RR Tenor Recommendation is required"),
      pricingRecommendation: isNotEmpty(
        "RR Pricing Recommendation is required"
      ),
      riskCategory: (value, values) =>
        values.analysisResult === RrAnalysisResult.Rejected
          ? null
          : isNotEmpty("Risk Category are required")(value),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (
      !form.isValid() ||
      !form.values.assignedId ||
      !form.values.assignedDate
    ) {
      return;
    }
    onSubmit(
      form.values.assignedId,
      form.values.assignedDate,
      omit(
        {
          ...form.values,
          document: form.values.document?.filePath ?? null,
          tenorRecommendation: Number(form.values.tenorRecommendation),
        },
        "assignedId",
        "assignedDate"
      ) as UpdateRiskReviewInput
    );
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleAssignRR = (value: number) => {
    form.setValues({
      assignedId: value,
      assignedDate: new Date(),
    });
  };

  const handleSelectAnalysisResult = (value: RrAnalysisResult) => {
    if (value === RrAnalysisResult.Rejected) {
      form.setValues({
        analysisResult: value,
        analysisResultDate: new Date(),
        riskCategory: null,
      });
    } else {
      form.setValues({ analysisResult: value, analysisResultDate: new Date() });
    }
  };

  const handleSelectTenor = (value: string | null) => {
    form.setValues((prevValues) => {
      return {
        ...prevValues,
        tenorRecommendation: value ?? undefined,
        pricingRecommendation: value
          ? get(pricingApprovedMapping, Number(value))
          : undefined,
      };
    });
  };

  const handleFileUploaded = (files: FileResult[]) =>
    form.setValues({ document: files[0] });

  const handleRemoveFile = () => form.setValues({ document: undefined });

  return (
    <Modal
      opened={opened}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      title={value ? "Edit Risk Review" : "Add Risk Review"}
      scrollAreaComponent={ScrollArea.Autosize}
      size="auto"
      onClose={handleClose}
    >
      <Flex direction="column" gap={20} className={classes.contentWrapper}>
        <SimpleGrid
          cols={2}
          breakpoints={[{ maxWidth: "lg", cols: 1 }]}
          spacing={20}
          verticalSpacing={12}
        >
          <Flex direction="column" gap={12}>
            <DatePickerInput
              label="RR Assigned Date"
              placeholder="Select RR Assigned Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              disabled={!form.values.assignedDate}
              {...form.getInputProps("assignedDate")}
            />
            <Select
              withinPortal
              required
              data={assigneesOptions}
              label="RR Assigned"
              placeholder="Select RR Assigned"
              size="m"
              value={
                form.values.assignedId ? String(form.values.assignedId) : null
              }
              onChange={(value) => handleAssignRR(Number(value))}
            />
            <DatePickerInput
              label="RR Analysis Result Date"
              placeholder="Select RR Analysis Result Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              maxDate={new Date()}
              popoverProps={{ withinPortal: true, zIndex: 1000 }}
              disabled={!form.values.analysisResultDate}
              {...form.getInputProps("analysisResultDate")}
            />
            <Select
              withinPortal
              required
              data={rrAnalysisResultOptions}
              label="RR Analysis Result"
              placeholder="Enter RR Analysis Result"
              size="m"
              {...form.getInputProps("analysisResult")}
              onChange={(value) =>
                handleSelectAnalysisResult(value as RrAnalysisResult)
              }
            />
            <Input.Wrapper label="RR Document" size="m" w="100%">
              <Dropzone
                maxWidth="unset"
                maxFiles={1}
                value={form.values.document ? [form.values.document] : null}
                onUploadSuccess={(value) => handleFileUploaded(value)}
                onRemove={handleRemoveFile}
              />
            </Input.Wrapper>
          </Flex>
          <Flex direction="column" gap={12}>
            <NumberInput
              label="RR Limit Recommendation"
              placeholder="Enter RR Limit Recommendation"
              required
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("limitRecommendation")}
            />
            <Select
              required
              size="m"
              label="RR Tenor Recommendation"
              placeholder="Enter RR Tenor Recommendation"
              data={tenorOptions}
              {...form.getInputProps("tenorRecommendation")}
              onChange={(value) => handleSelectTenor(value)}
            />
            <NumberInput
              label="RR Pricing Recommendation"
              placeholder="Enter RR Pricing Recommendation"
              required
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("pricingRecommendation")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required={
                form.values.analysisResult !== RrAnalysisResult.Rejected
              }
              data={rrRiskCategoryOptions}
              label="Risk Category Result"
              placeholder={
                form.values.analysisResult === RrAnalysisResult.Rejected
                  ? "N/A"
                  : "Enter Risk Category Result"
              }
              size="m"
              disabled={
                form.values.analysisResult === RrAnalysisResult.Rejected
              }
              {...form.getInputProps("riskCategory")}
            />
            <Textarea
              label="Notes"
              placeholder="Enter Notes"
              autosize
              size="m"
              minRows={3}
              maxRows={3}
              {...form.getInputProps("notes")}
            />
          </Flex>
        </SimpleGrid>
        <Flex gap={20} justify="flex-end">
          <Button variant="outlineBlue" uppercase onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default RiskReviewModal;
