import { Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import Spoiler from "../../../components/Spoiler/Spoiler.tsx";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import TableFilesDisplay from "../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { AIPromptResult } from "../../../types/ai/result.ts";
import { toLocalDate } from "../../../utils/date.ts";
import { getUserDisplayName } from "../../../utils/user.ts";

interface Props {
  data: AIPromptResult[] | null | undefined;
}

export const useAIPromptResultsTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "AIPromptResult",
            fieldName: "createdAt",
          }}
        >
          Run Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "customer",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "AIPromptResult",
            fieldName: "customer",
          }}
        >
          Customer Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "prompt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "AIPromptResult",
            fieldName: "prompt",
          }}
        >
          Prompt
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "files",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "AIPromptResult",
            fieldName: "files",
          }}
        >
          Data Uploaded
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "result",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "AIPromptResult",
            fieldName: "result",
          }}
        >
          Result
        </DatabaseMetadataPopover>
      ),
      width: "50vw",
    },
    {
      accessor: "createdBy",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "AIPromptResult",
            fieldName: "createdBy",
          }}
        >
          Run By
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
  ];

  const renderResult = useCallback((item: AIPromptResult) => {
    if (item.error) {
      return <Spoiler text={`Failed. ${item.error}`} maxStringLength={200} />;
    }
    return item.result.map((block, index) => (
      <Text
        key={index}
        dangerouslySetInnerHTML={{
          __html: block.text.replace(/\n/g, "<br/>"),
        }}
      />
    ));
  }, []);

  const getRow = useCallback(
    (item: AIPromptResult): TableRow => {
      return {
        id: item.id,
        createdAt: toLocalDate(item.createdAt),
        customer: item.customer.name,
        prompt: item.prompt.title,
        files: item.files?.length && <TableFilesDisplay files={item.files} />,
        result: renderResult(item),
        createdBy: getUserDisplayName(item.createdBy),
      };
    },
    [renderResult]
  );

  const rows = useMemo(() => {
    return data?.map(getRow);
  }, [data, getRow]);

  return [columns, rows];
};
