import { Spoiler as MantineSpoiler, Text } from "@mantine/core";

import { useStyles } from "./Spoilers.styles.ts";

interface SpoilerProps {
  text: string;
  maxStringLength?: number;
  width?: string | number;
}
const Spoiler = ({ text, maxStringLength = 17, width }: SpoilerProps) => {
  const { classes } = useStyles();

  return (
    <>
      {text.length > maxStringLength ? (
        <MantineSpoiler
          maxHeight={21}
          w={width}
          hideLabel="Hide"
          showLabel="More"
          className={classes.wrapper}
        >
          <Text
            className={classes.textWrapper}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </MantineSpoiler>
      ) : (
        text
      )}
    </>
  );
};

export default Spoiler;
